<template>
  <div class="d-flex flex-column-reverse mt-4">
    <button
      :class="{
        'align-self-center py-2 px-3 mt-5 w-75 h-50 rounded  shadow-sm pointer d-flex justify-content-center text-white w-fit-content': true,
        'bg-dark-green': validatedBox,
        'bg-gray': !validatedBox
      }"
      :disabled="!validatedBox"
      @click="navigateBoxes()"
    >
      Activa tu midboxX
    </button>

    <div class="d-flex flex-row justify-content-center mb-5">
      <div
        :class="{
          'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
          'brown-wGreyMedium-bold': !boxMeasu,
          'brown-green-regular': boxMeasu
        }"
      >
        <span>{{
          boxMeasu
            ? "Los datos de tu MidBox están subiendo al servidor"
            : "Los datos de tu MidBox no están subiendo al servidor"
        }}</span>
      </div>
      <div
        :class="{
          'd-flex  rounded-circle justify-content-center mx-4': true,
          'bg-gray ': !boxMeasu,
          'bg-green ': boxMeasu
        }"
        :style="{
          'min-width': '60px',
          width: '60px',
          'min-height': '60px',
          height: '60px'
        }"
        @click="validateMeas()"
      >
        <inline-svg
          :class="{
            'align-self-center justify-self-end ': true
          }"
          src="/icloud.svg"
          width="30px"
          height="30px"
          fill="black"
          aria-label="Loading..."
        />
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center mb-5">
      <div
        :class="{
          'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
          'brown-wGreyMedium-bold': !boxLinked,
          'brown-green-regular': boxLinked
        }"
      >
        <span>{{
          boxLinked
            ? "Tu MidBox esta vinculada con el celular y aplicación"
            : "Tu MidBox no esta vinculada con el celular y aplicación"
        }}</span>
      </div>
      <div
        :class="{
          'd-flex  rounded-circle justify-content-center mx-4': true,
          'bg-gray ': !boxLinked,
          'bg-green ': boxLinked
        }"
        :style="{
          'min-width': '60px',
          width: '60px',
          'min-height': '60px',
          height: '60px'
        }"
        @click="validateLink"
      >
        <inline-svg
          :class="{
            'align-self-center justify-self-end ': true
          }"
          src="/red-celular.svg"
          width="30px"
          height="30px"
          fill="black"
          aria-label="Loading..."
        />
      </div>
    </div>
    <div class="d-flex flex-row justify-content-center mb-5">
      <div
        :class="{
          'd-flex flex-column justify-content-center font-rem-0-7 text mx-4 ': true,
          'brown-wGreyMedium-bold': !boxOn,
          'brown-green-regular': boxOn
        }"
      >
        <span>{{
          boxOn ? "Tu MidBox esta encendida" : "Tu MidBox no esta encendida"
        }}</span>
      </div>
      <div
        :class="{
          'd-flex  rounded-circle justify-content-center mx-4': true,
          'bg-gray ': !boxOn,
          'bg-green ': boxOn
        }"
        :style="{
          'min-width': '60px',
          width: '60px',
          'min-height': '60px',
          height: '60px'
        }"
        @click="validateOn"
      >
        <inline-svg
          :class="{
            'align-self-center justify-self-end ': true
          }"
          :src="boxOn ? '/on.svg' : '/off.svg'"
          width="30px"
          height="30px"
          fill="black"
          aria-label="Loading..."
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
export default {
  data() {
    return {
      validatedBox: false,
      boxOn: false,
      boxLinked: false,
      boxMeasu: false
    }
  },
  computed: {
    ...mapGetters(["user", "boxBySerial"])
  },
  methods: {
    ...mapActions(["GET_OBJECTS"]),
    validateOn() {
      this.boxOn = true
    },
    validateLink() {
      if (this.boxBySerial(this.$router.query.serial)) {
        this.boxLinked = true
      }
    },
    validateMeas() {
      console.log("validating ")
      this.GET_OBJECTS("measurements").then(() => {
        const box = this.boxBySerial(this.$route.query.serial)
        const valid = box.port_data.find((port) => {
          return port.values.length > 0
        })
        if (valid) {
          this.boxOn = true
          this.boxMeasu = true
          this.boxLinked = true
          this.validatedBox = true
        }
      })
    },
    navigateBoxes() {
      this.$router.push({
        name: "View",
        params: { view: "boxes" },
        query: { tutorial: undefined }
      })
    }
  }
}
</script>

<style></style>
